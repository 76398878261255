import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Notification from './components/notifications'

class App extends Component {
  render() {
    return (
      <React.Fragment>
      <div  id="app">
       <Notification notification={{message:'Hello World1',type:'success'}}/>
       <Notification notification={{message:'Hello World2',type:'message'}}/>
       <Notification notification={{message:'Hello World3',type:'caution'}}/>
       <Notification notification={{message:'Hello World4',type:'error'}}/>
       <Notification notification={{message:'Hello World5'}}/>
       <Notification />
       </div>
      </React.Fragment>
    );
  }
}

export default App;
