import React, { Component } from 'react';

class Notification extends React.Component {
    render() {
        const {notification} =this.props;
      
     if(!notification){
       return(null);
     }
      var css="alert alert-";
      if(notification.type==='success') css+= 'success';
      else if (notification.type==='message') css+= 'info';
      else if (notification.type==='caution') css+= 'warning';
      else if (notification.type==='error') css+= 'danger';
      else css+= 'info';
      return (
        <React.Fragment>    
        <div className={css}>
         
        {notification.message}
        </div>
        </React.Fragment>
        );
    }
   
   };


   export default Notification;